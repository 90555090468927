// ** Initial user ability
// export const initialAbility = [
//   {
//     action: 'read',
//     subject: 'Auth'
//   }
// ]

export const initialAbility = []

// export const _ = undefined
export const _ =  []
